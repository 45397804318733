.Navbar {
    position: fixed;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 32px;

    height: 48px;
    padding: 2px 4px;
    align-items: center;
}

.Navbar, .Navbar * {
    transition: 0.4s;
}

.Navbar .Logo {
    grid-column: 1;
}

.Navbar .Logo svg {
    filter: drop-shadow(1px 1px 3px var(--background-color), 1px 1px 1px var(--background-color));
}

.Navbar .Logo img {
    height: 40px;
}

.Navbar .MainTitle {
    grid-column: 2;

    font-weight: bold;
    font-size: medium;
}

.Navbar .Icons {
    grid-column: 3;
}

.Navbar .Icons a {
    display: inline-block;
    width: var(--icon-size);
    height: var(--icon-size);

    cursor: pointer;
}

.Navbar {
    --icon-size: 28px;
}

.Navbar.Collapsed {
    --icon-size: 28px;
}

@media (min-width: 340px) and (min-height: 170px) {
    .Navbar {
        --icon-size: 44px;
    }

    .Navbar.Collapsed {
        --icon-size: 44px;
    }
}


@media (min-width: 600px) and (min-height: 300px) {
    .Navbar {
        height: 64px;
        padding: 4px 16px;

        --icon-size: 56px;
    }

    .Navbar .MainTitle {
        font-size: large;
    }

    .Navbar .Logo img {
        height: 56px;
    }
}

@media (min-width: 800px) and (min-height: 600px) {
    .Navbar {
        height: 120px;
        padding-top: 8px;
        padding-bottom: 8px;

        --icon-size: 72px;
    }

    .Navbar .MainTitle {
        font-size: xx-large;
    }

    .Navbar .Logo img {
        height: 104px;
    }
}

.Collapsed .MainTitle {
    font-size: medium;
}

.Navbar.Collapsed {
    background-color: var(--background-color);

    height: 48px;
    padding: 2px 4px;
}

.Collapsed .Logo img {
    height: 40px;
}

.Icons {
    text-align: right;
    grid-column: 3;
}