/* TODO: Optimize if font families are not used */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Playfair+Display:wght@400&display=swap');

:root {
    --title-font: "Playfair Display", serif;
    --title-weight: normal;
    --title-style: normal;

    --body-font: "Noto Sans", sans-serif;
    --body-weight: normal;
    --body-style: normal;

    scroll-behavior: smooth;
}

.dark {
    --background-color: #000000;
    --background-part: 0, 0, 0;
    --foreground-color: #ffffff;
    --foreground-part: 255, 255, 255;
    --accent-color: #ffed00;
}

.light {
    --background-color: #ffffff;
    --background-part: 255, 255, 255;
    --foreground-color: #000000;
    --foreground-part: 0, 0, 0;
    --accent-color: #000000;
}

.light, .dark {
    background-color: var(--background-color);
    color: var(--foreground-color);

    font-family: var(--body-font);
    font-style: var(--body-style);
    font-weight: var(--body-weight);
}

* {
    box-sizing: border-box;

    text-shadow: 1px 1px 3px var(--background-color), 1px 1px 1px var(--background-color);
}

body {
    margin: 0;
    background-color: black;
}

b, i, u, .accent {
    color: var(--accent-color);
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;

    color: var(--accent-color);

    font-family: var(--title-font);
    font-weight: var(--title-weight);
    font-style: var(--title-style);

    text-align: center;
}

a {
    text-decoration: none;
}

button, .button {
    cursor: pointer;

    font-size: large;

    background-color: var(--accent-color);
    color: var(--background-color);

    padding: 8px 12px;
    border: 2px outset var(--accent-color);
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
}

button, .button, input, select, option, textarea {
    text-shadow: none;
}

button:hover, .button:hover, button:focus, .button:focus {
    filter: brightness(70%);
}

button:active, .button:active {
    filter: brightness(40%);
    border-style: inset;
}

.fixed {
    width: 280px;
}

hgroup h1 {
    font-size: x-large;

    transition: 0.4s;
}

@media (min-width: 800px) and (min-height: 600px) {
    hgroup h1 {
        font-size: xx-large;
    }
}