@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&family=Playfair+Display:wght@400&display=swap);
/* TODO: Optimize if font families are not used */

:root {
    --title-font: "Playfair Display", serif;
    --title-weight: normal;
    --title-style: normal;

    --body-font: "Noto Sans", sans-serif;
    --body-weight: normal;
    --body-style: normal;

    scroll-behavior: smooth;
}

.dark {
    --background-color: #000000;
    --background-part: 0, 0, 0;
    --foreground-color: #ffffff;
    --foreground-part: 255, 255, 255;
    --accent-color: #ffed00;
}

.light {
    --background-color: #ffffff;
    --background-part: 255, 255, 255;
    --foreground-color: #000000;
    --foreground-part: 0, 0, 0;
    --accent-color: #000000;
}

.light, .dark {
    background-color: var(--background-color);
    color: var(--foreground-color);

    font-family: "Noto Sans", sans-serif;

    font-family: var(--body-font);
    font-style: normal;
    font-style: var(--body-style);
    font-weight: normal;
    font-weight: var(--body-weight);
}

* {
    box-sizing: border-box;

    text-shadow: 1px 1px 3px var(--background-color), 1px 1px 1px var(--background-color);
}

body {
    margin: 0;
    background-color: black;
}

b, i, u, .accent {
    color: var(--accent-color);
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;

    color: var(--accent-color);

    font-family: "Playfair Display", serif;

    font-family: var(--title-font);
    font-weight: normal;
    font-weight: var(--title-weight);
    font-style: normal;
    font-style: var(--title-style);

    text-align: center;
}

a {
    text-decoration: none;
}

button, .button {
    cursor: pointer;

    font-size: large;

    background-color: var(--accent-color);
    color: var(--background-color);

    padding: 8px 12px;
    border: 2px outset var(--accent-color);
    border-radius: 40px;
    margin-left: auto;
    margin-right: auto;

    text-align: center;
}

button, .button, input, select, option, textarea {
    text-shadow: none;
}

button:hover, .button:hover, button:focus, .button:focus {
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
}

button:active, .button:active {
    -webkit-filter: brightness(40%);
            filter: brightness(40%);
    border-style: inset;
}

.fixed {
    width: 280px;
}

hgroup h1 {
    font-size: x-large;

    transition: 0.4s;
}

@media (min-width: 800px) and (min-height: 600px) {
    hgroup h1 {
        font-size: xx-large;
    }
}
.HFill_HFillContainer__2_yOO {
    width: 100%;

    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-image: none;
    background-size: cover;
    background-origin: padding-box;
    background-clip: border-box;
}

.HFill_HFill__stD9N {
    width: 100%;
    padding: 36px 8px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.HFill_Anchor__3fToo {
    position: relative;
    visibility: hidden;
    display: block;
    top: -48px;
}
.HLimiter_HLimiter__1wDpS {
    flex-grow: 0;
    flex-shrink: 1;

    display: flex;
}

.Footer_Footer__1F_ug {
    font-size: x-small;
    text-align: center;
    padding: 8px;
}

.Box_Box__-xlV2 {
    width: 100%;
    height: 100%;

    padding: 12px;
    border-radius: 4px;

    text-align: justify;
}

.QuoteForm_QuoteForm__OQ7N0 {

}

.QuoteForm_Label__1tpI9 {
    display: flex;
    align-items: center;
    height: 38px;
    margin: 6px 0;
}

.QuoteForm_Label__1tpI9.QuoteForm_Notes__3w5zt {
    height: 160px;
}

.QuoteForm_Text__1hRMq {
    width: 40%;

    justify-content: right;
    margin-right: 8px;

    text-align: right;
}

.QuoteForm_Input__1H3k8 {
    width: 60%;
}

.QuoteForm_Text__1hRMq, .QuoteForm_Input__1H3k8 {
    display: flex;
    align-items: center;
    height: 100%;
}

.QuoteForm_Text__1hRMq *, .QuoteForm_Input__1H3k8 * {
    width: 100%;
    height: 100%;
}

.QuoteForm_Input__1H3k8 textarea {
    height: 160px;
    resize: none;
}

.QuoteForm_Button__3KhKU {
    display: block;
    text-align: center;
    width: 100%;
    max-width: 256px;
}

@media (max-width: 600px) {
    .QuoteForm_Text__1hRMq {
        font-size: small;
    }
}
.IconContainer_Container__3ko4U {
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 16px;
    grid-template-areas:
            "a b"
            "a c";
    margin-top: 6px;
    margin-bottom: 6px;

    width: 100%;
}

.IconContainer_Icon__1uvD1 {
    grid-area: a;
    margin-top: 8%;
    margin-bottom: 8%;

    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.IconContainer_Title__3dz5A {
    grid-area: b;
}

.IconContainer_Children__X8VYx {
    grid-area: c;
    margin-top: 4px;
    margin-bottom: 4px;
}

.Icon_Icon__377W- {
    width: var(--icon-size);
    height: var(--icon-size);
    line-height: var(--icon-size);
    font-size: calc(var(--icon-size) * 0.60);

    color: var(--accent-color);

    display: inline-block;

    transition: 0.4s;
    transition-property: width, height, line-height, font-size;
}

.Icon_Container__2S79Q {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Icon_Contents__3v0ZT {
    width: 100%;
    height: 100%;

    text-align: center;
}

.Navbar_Navbar__GcM95 {
    position: fixed;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 32px;

    height: 48px;
    padding: 2px 4px;
    align-items: center;
}

.Navbar_Navbar__GcM95, .Navbar_Navbar__GcM95 * {
    transition: 0.4s;
}

.Navbar_Navbar__GcM95 .Navbar_Logo__2jjF- {
    grid-column: 1;
}

.Navbar_Navbar__GcM95 .Navbar_Logo__2jjF- svg {
    -webkit-filter: drop-shadow(1px 1px 3px var(--background-color), 1px 1px 1px var(--background-color));
            filter: drop-shadow(1px 1px 3px var(--background-color), 1px 1px 1px var(--background-color));
}

.Navbar_Navbar__GcM95 .Navbar_Logo__2jjF- img {
    height: 40px;
}

.Navbar_Navbar__GcM95 .Navbar_MainTitle__5ZVIk {
    grid-column: 2;

    font-weight: bold;
    font-size: medium;
}

.Navbar_Navbar__GcM95 .Navbar_Icons__5wQfy {
    grid-column: 3;
}

.Navbar_Navbar__GcM95 .Navbar_Icons__5wQfy a {
    display: inline-block;
    width: var(--icon-size);
    height: var(--icon-size);

    cursor: pointer;
}

.Navbar_Navbar__GcM95 {
    --icon-size: 28px;
}

.Navbar_Navbar__GcM95.Navbar_Collapsed__2YXNG {
    --icon-size: 28px;
}

@media (min-width: 340px) and (min-height: 170px) {
    .Navbar_Navbar__GcM95 {
        --icon-size: 44px;
    }

    .Navbar_Navbar__GcM95.Navbar_Collapsed__2YXNG {
        --icon-size: 44px;
    }
}


@media (min-width: 600px) and (min-height: 300px) {
    .Navbar_Navbar__GcM95 {
        height: 64px;
        padding: 4px 16px;

        --icon-size: 56px;
    }

    .Navbar_Navbar__GcM95 .Navbar_MainTitle__5ZVIk {
        font-size: large;
    }

    .Navbar_Navbar__GcM95 .Navbar_Logo__2jjF- img {
        height: 56px;
    }
}

@media (min-width: 800px) and (min-height: 600px) {
    .Navbar_Navbar__GcM95 {
        height: 120px;
        padding-top: 8px;
        padding-bottom: 8px;

        --icon-size: 72px;
    }

    .Navbar_Navbar__GcM95 .Navbar_MainTitle__5ZVIk {
        font-size: xx-large;
    }

    .Navbar_Navbar__GcM95 .Navbar_Logo__2jjF- img {
        height: 104px;
    }
}

.Navbar_Collapsed__2YXNG .Navbar_MainTitle__5ZVIk {
    font-size: medium;
}

.Navbar_Navbar__GcM95.Navbar_Collapsed__2YXNG {
    background-color: var(--background-color);

    height: 48px;
    padding: 2px 4px;
}

.Navbar_Collapsed__2YXNG .Navbar_Logo__2jjF- img {
    height: 40px;
}

.Navbar_Icons__5wQfy {
    text-align: right;
    grid-column: 3;
}
.emoji {
    display: inline-block;
    width: auto;
    height: 1em;
    vertical-align: -0.075em;
}

.ButtonsContainer_ButtonsContainer__11uOF {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 8px 0;
    grid-gap: 8px;
    gap: 8px;
}

.ButtonsContainer_ButtonsContainer__11uOF > * {
    flex-grow: 0;
    margin: 8px;
}

.Mosaic_Mosaic__23QRu {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;

    justify-content: stretch;
    align-items: stretch;

    --mosaic-size: 256px;
}

.Mosaic_Tile__sUgto {
    flex-grow: 1;
    flex-shrink: 1;
    grid-gap: 8px;
    gap: 8px;

    width: var(--mosaic-size);

    display: flex;
    flex-direction: column;

    align-items: center;

    border-radius: 4px;
}

.Mosaic_Image__3IAim {
    width: var(--mosaic-size);
    height: var(--mosaic-size);

    margin-top: auto;
    margin-bottom: auto;

    border-radius: 4px;
}

.Mosaic_Caption__224pe {
    color: var(--foreground-color);
    text-align: center;
}

.Mosaic_Clickable__2K8jv:hover, .Mosaic_Clickable__2K8jv:focus {
    background-color: rgba(var(--foreground-part), 0.1);
}
.Clean_Clean__2RQPz {
    --accent-color: #00ffa2;
}

