.QuoteForm {

}

.Label {
    display: flex;
    align-items: center;
    height: 38px;
    margin: 6px 0;
}

.Label.Notes {
    height: 160px;
}

.Text {
    width: 40%;

    justify-content: right;
    margin-right: 8px;

    text-align: right;
}

.Input {
    width: 60%;
}

.Text, .Input {
    display: flex;
    align-items: center;
    height: 100%;
}

.Text *, .Input * {
    width: 100%;
    height: 100%;
}

.Input textarea {
    height: 160px;
    resize: none;
}

.Button {
    display: block;
    text-align: center;
    width: 100%;
    max-width: 256px;
}

@media (max-width: 600px) {
    .Text {
        font-size: small;
    }
}