.Container {
    display: grid;
    grid-template-columns: 44px 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: 16px;
    grid-template-areas:
            "a b"
            "a c";
    margin-top: 6px;
    margin-bottom: 6px;

    width: 100%;
}

.Icon {
    grid-area: a;
    margin-top: 8%;
    margin-bottom: 8%;

    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.Title {
    grid-area: b;
}

.Children {
    grid-area: c;
    margin-top: 4px;
    margin-bottom: 4px;
}
