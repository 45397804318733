.ButtonsContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin: 8px 0;
    gap: 8px;
}

.ButtonsContainer > * {
    flex-grow: 0;
    margin: 8px;
}
