.Mosaic {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    justify-content: stretch;
    align-items: stretch;

    --mosaic-size: 256px;
}

.Tile {
    flex-grow: 1;
    flex-shrink: 1;
    gap: 8px;

    width: var(--mosaic-size);

    display: flex;
    flex-direction: column;

    align-items: center;

    border-radius: 4px;
}

.Image {
    width: var(--mosaic-size);
    height: var(--mosaic-size);

    margin-top: auto;
    margin-bottom: auto;

    border-radius: 4px;
}

.Caption {
    color: var(--foreground-color);
    text-align: center;
}

.Clickable:hover, .Clickable:focus {
    background-color: rgba(var(--foreground-part), 0.1);
}