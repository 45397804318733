.Icon {
    width: var(--icon-size);
    height: var(--icon-size);
    line-height: var(--icon-size);
    font-size: calc(var(--icon-size) * 0.60);

    color: var(--accent-color);

    display: inline-block;

    transition: 0.4s;
    transition-property: width, height, line-height, font-size;
}

.Container {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Contents {
    width: 100%;
    height: 100%;

    text-align: center;
}
